.modal_order{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    align-items:center;
    background-color: rgb(141, 179, 47);
    color: white;
}
.order_block1{
    width: 90%;
    text-align: center;
    height: 20vw;
}
.order_block1 > h1{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 2.5vw;
}


.pen{
    display: flex;
    flex-direction: column;
    width: 100%;
}


.body_pen {
    width:100%;
    height: 6vw;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.body_pen > input{
    background: none;
    border: none;
    height:50% ;
    
}
.body_pen > input:nth-child(1){
    width: 15%;
    font-size: 1.5vw;
    height: auto;
    margin-left: 5%;
}
.body_pen > input:nth-child(2){
    width: 40%;
    font-size: 2vw;
    margin-right: 10%;
}




.logo_pen{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}
.logo_pen > input{
    -webkit-appearance: none;
    appearance: none;
}
.order_block2{
    width: 90%;
    height: 30vw;
    display: flex;
    justify-content: space-between;
}
.order_block2 h2{
    font-size: 2vw;
    text-align: center;
    margin: 2vw;
}
.color_pen{
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.color_pen nav{
    width: 90%;
    height: 80%;
    display: flex;
    justify-content: center;
}
.color_pen ul {
    display: flex;
    width: 80%;
    height: 70%;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
}


.color_pen li{
    border-radius: 50%;
    border: 1px solid white;
    width: 2.5vw;
    height: 2.5vw;
    margin: 0.5vw;
}
.color_pen li:active{
    border: 3px solid white;
}



.point_size_penCont {
    width: 35%;
    display: flex;
    flex-direction: column;
    /* justify-content: c; */
    
}
.point_size_penCont > div{
    display: flex;
    height: 15vw;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.point_size {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.point_size > span{
    font-size: 2vw;
    text-align: center;
    width: 6vw;
    height: 4vw;
    border: 8px solid white;
    border-radius: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 1px #00000029;
}

.count_pen{
    position: relative;
    width: 13vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
   
}

.count_pen>input{
    width: 5vw;
    height: 4vw;
}

.count_pen>button{
    /* width: 2vw; */
    height: 4vw;
    font-size: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* +- */


  
   .input-group-field {
    text-align: center;
  }
  
 .input-group-field::-webkit-inner-spin-button,
 .input-group-field ::-webkit-outer-spin-button {
    -webkit-appearance: none;
            appearance: none;
  }
  
 .input-group-button {
    border-radius: 50%;
    padding: 0.25em 0.8em;
  }
  /*  */

.point_color{
    
    text-align: center;
    width: 35%;
    display: flex;
    flex-direction: column;
}

.point_color ul{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

}

.point_color li{
    border-radius: 50%;
    border: 1px solid white;
    width: 3vw;
    height: 3vw;
    margin: 0.5vw;
}

.penOrder_info{
    width: 100%;
    height: 30vh;
    font-size: 2.5vw;
    color: rgb(158, 191, 78);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pen_price{
    display: flex;
    justify-content:center;
    width: 30vw;
}

.buy_next{
    width: 20vw;
    position: absolute;
    right: 5vw;
    bottom: 5vw;
    display: flex;
    justify-content: space-around;
}

.color_pen{
    display: flex;
    justify-content: center;
}



.container_basket{
    overflow-y: scroll;
    /*height: 40vw;*/
}

.container_basket::-webkit-scrollbar {
    width: 1em;
  }
   
  .container_basket::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(19, 175, 19, 0.3);
  }
   
  .container_basket::-webkit-scrollbar-thumb {
    background-color: rgb(169, 169, 169);
    outline: 1px solid rgb(117, 144, 112);
  }