footer{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: flex-end;
    background-color: #e7e7e7;
    position: relative;
}
footer > img {
    width: 100%;
    height: 50%;
  
}
.footer_contacts{
    width: 250px;
    height: 200px;
    position: absolute;
    right: 50px;
    top: -50px;
    z-index: 10;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    justify-content: center;
    padding-left: 20px;
}
h2{
    font-size: 15px;
    color: #00000081;

    margin: 5;
}
p{
    font-size: 18px;
    margin: 0;
    color: #00000081;
    font-weight: 800;
    letter-spacing: 2.62px;
}
.footer_contacts::before{
    content: '';
    display: block;
    width: 100%;
    height: 50px;
    background-color: #ffffff80;
    position: absolute;
    bottom: -50px;
    left: 0;
}