.basket{
    width: 100%;
    background: rgb(155, 188, 73);
    border-bottom:1px solid rgb(125, 170, 145);
    display: flex;
}

.basket_pen{
    width: 80%;
}

.pen_image{
    width:100%;
    height: 6vw;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.basket_pen_color{
    display: flex;
    justify-self: center;
    justify-content: center;
}
.basket_pen_color > div{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.remove_buttons{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.rem_btn{
    width: 4vw;
    font-size: 2vw;
    height: 3.7 vw;
}