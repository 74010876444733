.slider1{
    width: 100%;
    height: 100%;
    background-image: url(../../img/Slider1bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.slider1_title > h2 {
    color: #ffffff;
    font-family: Verdana;
    font-size: 47px;
    font-weight: 400;
    letter-spacing: 6.02px;
    text-transform: uppercase;
}
.slider_1{
    width: 100%;
    height: 100%;
   display: flex;
   flex-direction: column;

    
}




.slider1  .carousel-item {
    display: flex;
    justify-content:center;
    align-items: center;
   height: 500px;
 
  }
  .slider1 > .carousel-track .active{
    display: flex;
    align-items: center;
  }
  
  .slider1  .active img {
      width: 100%;
      height: 100%;

  } 
  
