*,
*::before,
*::after {
  outline: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}
body{
  width: 100%;
  position: relative;
}
body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgb(169, 169, 169);
  outline: 1px solid rgb(117, 144, 112);
}
.App{
  position: relative;
}
.container{
  width: 100%;
  height: 100vh;
  position: relative;
}
.slider{
  width: 100%;  
  height: 100%;
  position: relative;
}
.slider:nth-of-type(1) {
  height: calc(100% - 96px);
}
.move {
  width: 20px;
  position: absolute;
  bottom: 10px;
  left: calc(50% - 10px);
  display: flex;
  z-index: 1;
  cursor: pointer;

}
.slider > div {
  width: 100%;
  height: 100%;
  display: flex;
}
.slider > div img {
  width: 100%;
  height: 100%;
}
.langauge{
  position: absolute;
  right: 50px;
  top: 40px;
  z-index: 10;
}
.langauge > select {
  border:  2px solid #87af24;
  color: #87af24;
  font-weight: bold;
  background-color: #ffffff;
}