.btn {
  width: 15vw;
  height: 3vw;
  position: absolute;
  padding: 10px 15px;
  background-color: #fafafa;
  border: none;
  color: rgb(141, 179, 47);
  font-weight: 600;
  cursor: pointer;
  font-size: 1vw;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  top: 2vw;
  left: 5vw;
}

.buy,.count{
  position: static;
}
.count{
  width: 4vw;
  height: 2vw;
}
.rem_btn{
  position: static;
  height: 3.7vw;
}
.btn:hover {
  background-color: rgb(203, 218, 167);
}

.btn[disabled] {
  opacity: .5;
  cursor: not-allowed;
}

.btn.active {
  background-color:  rgb(206, 233, 143);;
  border: 1px solid  rgb(97, 114, 58);
  box-shadow: 0px 0 2px 0px #C0C0C0;
}
