header{
    width: 100%;
    height: 96px;
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 129px;
    border-bottom: 2px solid #7e8182;
    position: relative;
}
.logo{
    width: 250px;
    height: 50px;
}
.logo > img {
    width: 100%;
    height: 100%;
}
.header_navigation > ul{
    
    height: 100%;
    display: flex;
    align-items: center;

}
.header_navigation > ul li {
    margin: 0px 15px
}
.header_navigation > ul li a {
    color:#87af24;
    font-size: 18px;
    text-transform: uppercase;
}
.order{
    width : 15px;
    height: 15px;
    margin-left: 10px
}
.soc_contacts{
    width: 72px;
    height: 130px;
    border: 4px solid #fffafa;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    right: 0;
    top: calc(50% - 65px);
    z-index: 1;
    border-radius: 30px 0px 0px 30px;
}
.soc_contacts > a img {
    width: 38px;
}
.burger{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 100;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.burger > span{
    display: block;
    width: 25px;
    height: 2px;
    background-color: #87af24;
    transition: .3s;
    cursor: pointer;
}

@media (max-width:1340px){
    .header_navigation > ul li {
        margin: 0px 10px
    }
    .header_navigation > ul li a {
        font-size: 16px;
    }
    .order{
        width : 10px;
        height: 10px;
        margin-left: 10px
    }
}
@media (max-width:1200px){
    .burger{
        display: flex;
       
    }
    .header_navigation > ul{
        flex-direction: column;
        background-color: black;
        width: 100%;
        height: 100vh;
        position: absolute;
        padding-top: 100px;
        top: 0;
        left: 0;
        z-index: 10;
       transform: scaleX(0);
       transform-origin: right;
       transition: .5s;
    }
    .header_navigation > ul li {
        border: 2px solid #87af24;
        margin: 5px;
        width: 300px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;
    }
    .header_navigation > ul li:hover{
        background-color: #87af24;
        
    }
    .header_navigation > ul li:hover a{
       color: black;
        
    }
}