@import url(https://fonts.googleapis.com/css2?family=Fondamento&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header{
    width: 100%;
    height: 96px;
    background-color: #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 129px;
    border-bottom: 2px solid #7e8182;
    position: relative;
}
.logo{
    width: 250px;
    height: 50px;
}
.logo > img {
    width: 100%;
    height: 100%;
}
.header_navigation > ul{
    
    height: 100%;
    display: flex;
    align-items: center;

}
.header_navigation > ul li {
    margin: 0px 15px
}
.header_navigation > ul li a {
    color:#87af24;
    font-size: 18px;
    text-transform: uppercase;
}
.order{
    width : 15px;
    height: 15px;
    margin-left: 10px
}
.soc_contacts{
    width: 72px;
    height: 130px;
    border: 4px solid #fffafa;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    right: 0;
    top: calc(50% - 65px);
    z-index: 1;
    border-radius: 30px 0px 0px 30px;
}
.soc_contacts > a img {
    width: 38px;
}
.burger{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 100;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.burger > span{
    display: block;
    width: 25px;
    height: 2px;
    background-color: #87af24;
    transition: .3s;
    cursor: pointer;
}

@media (max-width:1340px){
    .header_navigation > ul li {
        margin: 0px 10px
    }
    .header_navigation > ul li a {
        font-size: 16px;
    }
    .order{
        width : 10px;
        height: 10px;
        margin-left: 10px
    }
}
@media (max-width:1200px){
    .burger{
        display: flex;
       
    }
    .header_navigation > ul{
        flex-direction: column;
        background-color: black;
        width: 100%;
        height: 100vh;
        position: absolute;
        padding-top: 100px;
        top: 0;
        left: 0;
        z-index: 10;
       transform: scaleX(0);
       transform-origin: right;
       transition: .5s;
    }
    .header_navigation > ul li {
        border: 2px solid #87af24;
        margin: 5px;
        width: 300px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;
    }
    .header_navigation > ul li:hover{
        background-color: #87af24;
        
    }
    .header_navigation > ul li:hover a{
       color: black;
        
    }
}
.bttn{
    width: 300px;
    height: 100px;
    border: 6px solid #ffffff;
    position: absolute;
    bottom: 150px;
    left: calc(50% - 150px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    perspective: 500px;
 
}
.bttn > button {
    width: 100%;
    height: 100%;
    background-color: #83ad1d;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 4.86px;
    font-family: "Segoe UI";
    font-weight: 400;
    text-transform: uppercase;
    border: 2px solid transparent;
    transform-style: preserve-3d;
    transition: .3s;
    
}
.bttn > button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff ;
    border: 2px solid #83ad1d;
    transform-origin: left;
    transition: .3s;
    transform: scaleX(0);
    z-index: -1;
}
.bttn > button:hover{
    color: #83ad1d;
    
}
.bttn > button:hover::before {
    transform: scaleX(1);
}
::-webkit-scrollbar{

}
.WhyChooseUs{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.title{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title > h2{
color: #83ad1d;
font-size: 43px;
font-weight: 700;
letter-spacing: 0.43px;
text-transform: lowercase;
}
.title > span{
    color: #199135;
    font-size: 55px;
    padding-left: 25px;
    font-family: 'Fondamento', cursive;
    font-weight: 400;
    }
.content{
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.card{
    width: 297px;
    height: 353px;
    background-color: #83ad1d;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

  
}
.card p {
    font-size: 38px;
    font-weight: bold;
    color: #83ad1d;
    -webkit-text-stroke: 2px #fff;
}
.card > img {
    width: 125px;
    height: 115px;
}

@media (max-width:1200px) {
   .card{

       margin: 0px 5px;
   } 
}
@media (max-width: 770px) {
   .content{
       flex-direction: column;
       width: 100%;
      
   }
   .card{
       width: 80%;
       margin: 5px 0;
   }
 }
.block-1, 
.block-2,
.block-3,
.block-4
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    
   
}
.block-1> .content, 
.block-2> .content,
.block-3> .content,
.block-4> .content
{
    color: #e7e7e7;
    font-family: "Segoe Print";
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2.55px;
}

.block-1,
.block-2
{
    justify-content: space-between;
    padding: 10% 0;
}

.block-3
{
    justify-content: flex-end;

}
.block-4
{
    justify-content: flex-start;

}



.block-1 > img {
    order: 1;
    width: 30%;
    height: 100%;
    transform: translateX(-15%);
}

.block-1 > .content{
    order: 2;
    width: 50%;
    height: 100%;
    padding: 50px;
    background-color: #83ad1d;
    position: relative;
 
}

.block-1 > .content::before{
    content:  '';
    display: block;
    border: 15px solid #ffffff;
    width: 80%;
    height: 100%;
    position: absolute;
    left: -10%;
    bottom: -20%;
    z-index: -11;
}

.block-2 > img {
    order: 2;
    width: 30%;
    height: 100%;
    transform: translateX(-15%);
}
.block-2 > .content{
    order: 1;
    width: 50%;
    height: 100%;
    padding: 50px;
    background-color: #83ad1d;
    position: relative;

}

.block-2 > .content::before{
    content:  '';
    display: block;
    border: 15px solid #ffffff;
    width: 80%;
    height: 100%;
    position: absolute;
    right: -10%;
    bottom: -20%;
    z-index: -11;
}
.block-3 > img {
    order: 1;
    width: 35%;
    height: 60%;
   position: absolute;
   left: calc(10% + 35px);
   z-index: 1;
   
}
.block-3 > .content{
    order: 2;
    width: 90%;
    height: 70%;
    padding: 50px 50px 50px 45%;
    background: linear-gradient(45deg, #83ad1d80, #83ad1d);
    position: relative;
    color: #e7e7e7;
}

.block-3 > .content::before{
    content:  '';
    display: block;
    border: 10px solid #ffffff;
    width: 50%;
    height: 120%;
    position: absolute;
    left: -5%;
    top: -10%;
    z-index: 1;
}
.block-4 > img {
    order: 2;
    width: 35%;
    height: 70%;
    position: absolute;
   right: 12%;
   z-index: 1;
   
}
.block-4 > .content{
    order: 1;
    width: 90%;
    height: 70%;
    padding: 50px 45% 50px 50px;
    background: linear-gradient(45deg, #83ad1d, #83ad1d30);
    position: relative;
    
}

.block-4 > .content::before{
    content:  '';
    display: block;
    border: 35px solid #ffffff;
    width: 43%;
    height: 100%;
    position: absolute;
    right: 0;
    top:0%;
    z-index: 1;
}


.slider1{
    width: 100%;
    height: 100%;
    background-image: url(/static/media/Slider1bg.f8ffbbb4.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.slider1_title > h2 {
    color: #ffffff;
    font-family: Verdana;
    font-size: 47px;
    font-weight: 400;
    letter-spacing: 6.02px;
    text-transform: uppercase;
}
.slider_1{
    width: 100%;
    height: 100%;
   display: flex;
   flex-direction: column;

    
}




.slider1  .carousel-item {
    display: flex;
    justify-content:center;
    align-items: center;
   height: 500px;
 
  }
  .slider1 > .carousel-track .active{
    display: flex;
    align-items: center;
  }
  
  .slider1  .active img {
      width: 100%;
      height: 100%;

  } 
  

.slider2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.slider2_title > h2 {
    color: #83ad1d;
  font-family: Verdana;
  font-size: 47px;
  font-weight: 400;
  letter-spacing: 6.02px;
  text-transform: uppercase;
}
.slider_2 {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(151, 186, 66, 0.84) 22%,
    #83ad1d 51%,
    rgba(159, 192, 80, 0.77) 73%,
    rgba(255, 255, 255, 0) 100%
  );
}


.slider2  .carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
}

.slider2  .active img {
    width: 100%;
    height: 100%;

} 


.slider_img > img {
  width: 150px;
  height: 150px;
}




footer{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: flex-end;
    background-color: #e7e7e7;
    position: relative;
}
footer > img {
    width: 100%;
    height: 50%;
  
}
.footer_contacts{
    width: 250px;
    height: 200px;
    position: absolute;
    right: 50px;
    top: -50px;
    z-index: 10;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    justify-content: center;
    padding-left: 20px;
}
h2{
    font-size: 15px;
    color: #00000081;

    margin: 5;
}
p{
    font-size: 18px;
    margin: 0;
    color: #00000081;
    font-weight: 800;
    letter-spacing: 2.62px;
}
.footer_contacts::before{
    content: '';
    display: block;
    width: 100%;
    height: 50px;
    background-color: #ffffff80;
    position: absolute;
    bottom: -50px;
    left: 0;
}
.modal_order{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    align-items:center;
    background-color: rgb(141, 179, 47);
    color: white;
}
.order_block1{
    width: 90%;
    text-align: center;
    height: 20vw;
}
.order_block1 > h1{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 2.5vw;
}


.pen{
    display: flex;
    flex-direction: column;
    width: 100%;
}


.body_pen {
    width:100%;
    height: 6vw;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.body_pen > input{
    background: none;
    border: none;
    height:50% ;
    
}
.body_pen > input:nth-child(1){
    width: 15%;
    font-size: 1.5vw;
    height: auto;
    margin-left: 5%;
}
.body_pen > input:nth-child(2){
    width: 40%;
    font-size: 2vw;
    margin-right: 10%;
}




.logo_pen{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}
.logo_pen > input{
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
}
.order_block2{
    width: 90%;
    height: 30vw;
    display: flex;
    justify-content: space-between;
}
.order_block2 h2{
    font-size: 2vw;
    text-align: center;
    margin: 2vw;
}
.color_pen{
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.color_pen nav{
    width: 90%;
    height: 80%;
    display: flex;
    justify-content: center;
}
.color_pen ul {
    display: flex;
    width: 80%;
    height: 70%;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
}


.color_pen li{
    border-radius: 50%;
    border: 1px solid white;
    width: 2.5vw;
    height: 2.5vw;
    margin: 0.5vw;
}
.color_pen li:active{
    border: 3px solid white;
}



.point_size_penCont {
    width: 35%;
    display: flex;
    flex-direction: column;
    /* justify-content: c; */
    
}
.point_size_penCont > div{
    display: flex;
    height: 15vw;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.point_size {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.point_size > span{
    font-size: 2vw;
    text-align: center;
    width: 6vw;
    height: 4vw;
    border: 8px solid white;
    border-radius: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 1px #00000029;
}

.count_pen{
    position: relative;
    width: 13vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
   
}

.count_pen>input{
    width: 5vw;
    height: 4vw;
}

.count_pen>button{
    /* width: 2vw; */
    height: 4vw;
    font-size: 1.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* +- */


  
   .input-group-field {
    text-align: center;
  }
  
 .input-group-field::-webkit-inner-spin-button,
 .input-group-field ::-webkit-outer-spin-button {
    -webkit-appearance: none;
            appearance: none;
  }
  
 .input-group-button {
    border-radius: 50%;
    padding: 0.25em 0.8em;
  }
  /*  */

.point_color{
    
    text-align: center;
    width: 35%;
    display: flex;
    flex-direction: column;
}

.point_color ul{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

}

.point_color li{
    border-radius: 50%;
    border: 1px solid white;
    width: 3vw;
    height: 3vw;
    margin: 0.5vw;
}

.penOrder_info{
    width: 100%;
    height: 30vh;
    font-size: 2.5vw;
    color: rgb(158, 191, 78);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pen_price{
    display: flex;
    justify-content:center;
    width: 30vw;
}

.buy_next{
    width: 20vw;
    position: absolute;
    right: 5vw;
    bottom: 5vw;
    display: flex;
    justify-content: space-around;
}

.color_pen{
    display: flex;
    justify-content: center;
}



.container_basket{
    overflow-y: scroll;
    /*height: 40vw;*/
}

.container_basket::-webkit-scrollbar {
    width: 1em;
  }
   
  .container_basket::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(19, 175, 19, 0.3);
  }
   
  .container_basket::-webkit-scrollbar-thumb {
    background-color: rgb(169, 169, 169);
    outline: 1px solid rgb(117, 144, 112);
  }
.btn {
  width: 15vw;
  height: 3vw;
  position: absolute;
  padding: 10px 15px;
  background-color: #fafafa;
  border: none;
  color: rgb(141, 179, 47);
  font-weight: 600;
  cursor: pointer;
  font-size: 1vw;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  top: 2vw;
  left: 5vw;
}

.buy,.count{
  position: static;
}
.count{
  width: 4vw;
  height: 2vw;
}
.rem_btn{
  position: static;
  height: 3.7vw;
}
.btn:hover {
  background-color: rgb(203, 218, 167);
}

.btn[disabled] {
  opacity: .5;
  cursor: not-allowed;
}

.btn.active {
  background-color:  rgb(206, 233, 143);;
  border: 1px solid  rgb(97, 114, 58);
  box-shadow: 0px 0 2px 0px #C0C0C0;
}

.basket{
    width: 100%;
    background: rgb(155, 188, 73);
    border-bottom:1px solid rgb(125, 170, 145);
    display: flex;
}

.basket_pen{
    width: 80%;
}

.pen_image{
    width:100%;
    height: 6vw;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.basket_pen_color{
    display: flex;
    justify-self: center;
    justify-content: center;
}
.basket_pen_color > div{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.remove_buttons{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.rem_btn{
    width: 4vw;
    font-size: 2vw;
    height: 3.7 vw;
}
*,
*::before,
*::after {
  outline: none;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}
body{
  width: 100%;
  position: relative;
}
body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgb(169, 169, 169);
  outline: 1px solid rgb(117, 144, 112);
}
.App{
  position: relative;
}
.container{
  width: 100%;
  height: 100vh;
  position: relative;
}
.slider{
  width: 100%;  
  height: 100%;
  position: relative;
}
.slider:nth-of-type(1) {
  height: calc(100% - 96px);
}
.move {
  width: 20px;
  position: absolute;
  bottom: 10px;
  left: calc(50% - 10px);
  display: flex;
  z-index: 1;
  cursor: pointer;

}
.slider > div {
  width: 100%;
  height: 100%;
  display: flex;
}
.slider > div img {
  width: 100%;
  height: 100%;
}
.langauge{
  position: absolute;
  right: 50px;
  top: 40px;
  z-index: 10;
}
.langauge > select {
  border:  2px solid #87af24;
  color: #87af24;
  font-weight: bold;
  background-color: #ffffff;
}
