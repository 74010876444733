.bttn{
    width: 300px;
    height: 100px;
    border: 6px solid #ffffff;
    position: absolute;
    bottom: 150px;
    left: calc(50% - 150px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    perspective: 500px;
 
}
.bttn > button {
    width: 100%;
    height: 100%;
    background-color: #83ad1d;
    border: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 4.86px;
    font-family: "Segoe UI";
    font-weight: 400;
    text-transform: uppercase;
    border: 2px solid transparent;
    transform-style: preserve-3d;
    transition: .3s;
    
}
.bttn > button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff ;
    border: 2px solid #83ad1d;
    transform-origin: left;
    transition: .3s;
    transform: scaleX(0);
    z-index: -1;
}
.bttn > button:hover{
    color: #83ad1d;
    
}
.bttn > button:hover::before {
    transform: scaleX(1);
}
::-webkit-scrollbar{

}