.block-1, 
.block-2,
.block-3,
.block-4
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    
   
}
.block-1> .content, 
.block-2> .content,
.block-3> .content,
.block-4> .content
{
    color: #e7e7e7;
    font-family: "Segoe Print";
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2.55px;
}

.block-1,
.block-2
{
    justify-content: space-between;
    padding: 10% 0;
}

.block-3
{
    justify-content: flex-end;

}
.block-4
{
    justify-content: flex-start;

}



.block-1 > img {
    order: 1;
    width: 30%;
    height: 100%;
    transform: translateX(-15%);
}

.block-1 > .content{
    order: 2;
    width: 50%;
    height: 100%;
    padding: 50px;
    background-color: #83ad1d;
    position: relative;
 
}

.block-1 > .content::before{
    content:  '';
    display: block;
    border: 15px solid #ffffff;
    width: 80%;
    height: 100%;
    position: absolute;
    left: -10%;
    bottom: -20%;
    z-index: -11;
}

.block-2 > img {
    order: 2;
    width: 30%;
    height: 100%;
    transform: translateX(-15%);
}
.block-2 > .content{
    order: 1;
    width: 50%;
    height: 100%;
    padding: 50px;
    background-color: #83ad1d;
    position: relative;

}

.block-2 > .content::before{
    content:  '';
    display: block;
    border: 15px solid #ffffff;
    width: 80%;
    height: 100%;
    position: absolute;
    right: -10%;
    bottom: -20%;
    z-index: -11;
}
.block-3 > img {
    order: 1;
    width: 35%;
    height: 60%;
   position: absolute;
   left: calc(10% + 35px);
   z-index: 1;
   
}
.block-3 > .content{
    order: 2;
    width: 90%;
    height: 70%;
    padding: 50px 50px 50px 45%;
    background: linear-gradient(45deg, #83ad1d80, #83ad1d);
    position: relative;
    color: #e7e7e7;
}

.block-3 > .content::before{
    content:  '';
    display: block;
    border: 10px solid #ffffff;
    width: 50%;
    height: 120%;
    position: absolute;
    left: -5%;
    top: -10%;
    z-index: 1;
}
.block-4 > img {
    order: 2;
    width: 35%;
    height: 70%;
    position: absolute;
   right: 12%;
   z-index: 1;
   
}
.block-4 > .content{
    order: 1;
    width: 90%;
    height: 70%;
    padding: 50px 45% 50px 50px;
    background: linear-gradient(45deg, #83ad1d, #83ad1d30);
    position: relative;
    
}

.block-4 > .content::before{
    content:  '';
    display: block;
    border: 35px solid #ffffff;
    width: 43%;
    height: 100%;
    position: absolute;
    right: 0;
    top:0%;
    z-index: 1;
}

