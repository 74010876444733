.slider2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.slider2_title > h2 {
    color: #83ad1d;
  font-family: Verdana;
  font-size: 47px;
  font-weight: 400;
  letter-spacing: 6.02px;
  text-transform: uppercase;
}
.slider_2 {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(151, 186, 66, 0.84) 22%,
    #83ad1d 51%,
    rgba(159, 192, 80, 0.77) 73%,
    rgba(255, 255, 255, 0) 100%
  );
}


.slider2  .carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
}

.slider2  .active img {
    width: 100%;
    height: 100%;

} 


.slider_img > img {
  width: 150px;
  height: 150px;
}



